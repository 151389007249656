import SectionListProps from 'components/HomePage/components/SectionBrowser/SectionListProps';
import { SectionSlot } from 'components/Toolkit/SectionSlot/SectionSlot';
import MotorsIcons from 'components/HomePage/Icons/MotorsIcons';
import { getDashIcon } from 'components/HomePage/Icons/DashIcons';
import SectionContainer from 'components/HomePage/components/SectionBrowser/styles/SectionContainer';
import { restoreScrollPosition } from 'helpers/scroll';

function MotorSectionsList({ sections, dashLinks }: SectionListProps) {
  const updatedSections = [
    ...sections.slice(0, 1),
    {
      displayName: 'Cars from Dealerships',
      name: 'carsfromdealerships',
      id: -1,
      link: 'cars?source=trade',
    },
    {
      displayName: 'New Cars',
      name: 'new-cars',
      id: -1,
      link: `cars?mileage_to=1000&year_from=${new Date().getFullYear()}`,
    },
    ...sections.slice(1),
  ];

  return (
    <SectionContainer data-testid="motor-section-list">
      {dashLinks?.map(({ displayName, count, icon, url }, index) => {
        return (
          <li key={index}>
            <a href={url} onClick={() => restoreScrollPosition(url)}>
              <SectionSlot
                icon={getDashIcon(icon)}
                text={displayName}
                quantity={count}
              />
            </a>
          </li>
        );
      })}
      {updatedSections.map((section, index) => {
        const link = section.link ? section.link : section.name;
        return (
          <li key={index}>
            <a
              href={`/${link}`}
              onClick={() => restoreScrollPosition(`/${link}`)}
            >
              <SectionSlot
                icon={MotorsIcons[section.name]}
                text={section.displayName}
              />
            </a>
          </li>
        );
      })}
    </SectionContainer>
  );
}

export { MotorSectionsList };
