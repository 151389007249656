import { SavedSearches64 } from 'components/Toolkit/Base64/Sections';
export interface Map {
  [name: string]: JSX.Element;
}
interface AliasMap {
  [name: string]: string;
}

const SAVED_SEARCHES = 'savedSearches';

const aliases: AliasMap = {
  'star-solid': SAVED_SEARCHES,
};

export function getDashIcon(key: string) {
  if (DashIcons[key]) {
    return DashIcons[key];
  }
  if (aliases[key]) {
    return DashIcons[aliases[key]];
  }
  return null;
}

const DashIcons: Map = {
  [SAVED_SEARCHES]: <SavedSearches64 altText="" />,
};

export default DashIcons;
