import {
  AllCarsMotors64,
  Cars64,
  CarsFromDealerships64,
  NewCars64,
  Commercials64,
  PlantMachinery64,
  VintageCars64,
  Motorbikes64,
  DamagedCars64,
  MotorbikeExtras64,
  Boats64,
  BoatExtras64,
  Campers64,
  Caravans64,
  CarExtras64,
  CarParts64,
  CoachesBuses64,
  ModifiedCars64,
  OtherMotors64,
  Quads64,
  RallyCars64,
  Scooters64,
  Trailers64,
  Trucks64,
  VintageBikes64,
} from 'components/Toolkit/Base64/Sections';

export interface Map {
  [name: string]: JSX.Element;
}

const MotorsIcons: Map = {
  cars: <Cars64 altText="" />,
  carsfromdealerships: <CarsFromDealerships64 altText="" />,
  'new-cars': <NewCars64 altText="" />,
  commercials: <Commercials64 altText="" />,
  plantmachinery: <PlantMachinery64 altText="" />,
  vintagecars: <VintageCars64 altText="" />,
  motorbikes: <Motorbikes64 altText="" />,
  damagedcars: <DamagedCars64 altText="" />,
  boats: <Boats64 altText="" />,
  boatextras: <BoatExtras64 altText="" />,
  campers: <Campers64 altText="" />,
  caravans: <Caravans64 altText="" />,
  carextras: <CarExtras64 altText="" />,
  carparts: <CarParts64 altText="" />,
  coaches: <CoachesBuses64 altText="" />,
  modifiedcars: <ModifiedCars64 altText="" />,
  motorbikeextras: <MotorbikeExtras64 altText="" />,
  othermotor: <OtherMotors64 altText="" />,
  quads: <Quads64 altText="" />,
  rallycars: <RallyCars64 altText="" />,
  scooters: <Scooters64 altText="" />,
  trailers: <Trailers64 altText="" />,
  trucks: <Trucks64 altText="" />,
  vintagebikes: <VintageBikes64 altText="" />,
  motor: <AllCarsMotors64 altText="" />,
};

export default MotorsIcons;
